class ProjectUnit {
  async getTypes() {
    const data = await fetch(`${process.env.REACT_APP_API_URL}/projectunits/types`, {
      headers: {
        'x-access-token': localStorage.getItem('token')
      }
    });
    return await data.json();
  }

  async getStatuses() {
    const data = await fetch(`${process.env.REACT_APP_API_URL}/projectunits/statuses`, {
      headers: {
        'x-access-token': localStorage.getItem('token')
      }
    });
    return await data.json();
  }

  async getAreaTypes() {
    const data = await fetch(`${process.env.REACT_APP_API_URL}/projectunits/areaTypes`, {
      headers: {
        'x-access-token': localStorage.getItem('token')
      }
    });
    return await data.json();
  }

  async get(projectId, filter) {
    const data = await fetch(`${process.env.REACT_APP_API_URL}/projectunits/project/${projectId}?q=${filter}`, {
      headers: {
        'x-access-token': localStorage.getItem('token')
      }
    });
    return await data.json();
  }

  async getById(id) {
    if (id === '0') {
      return {};
    }
    const data = await fetch(`${process.env.REACT_APP_API_URL}/projectunits/${id}`, {
      headers: {
        'x-access-token': localStorage.getItem('token')
      }
    });
    return await data.json();
  }

  async addUpdate(record) {
    const data = await fetch(`${process.env.REACT_APP_API_URL}/projectunits/${record.id || ''}`, {
      method: record.id ? 'PUT' : 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('token')
      },
      body: JSON.stringify(record)
    });
    return await data.json();
  }

  async delete(record) {
    const data = await fetch(`${process.env.REACT_APP_API_URL}/projectunits/${record.id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('token')
      }
    });
    return await data.json();
  }

  async copy(record) {
    const data = await fetch(`${process.env.REACT_APP_API_URL}/projectunits/${record.id}/copy`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('token')
      },
      body: JSON.stringify({...record, name: `${record.name} (Copy)`})
    });
    return await data.json();
  }

  async export(projectId) {
    const data = await fetch(`${process.env.REACT_APP_API_URL}/projectunits/project/${projectId}/export`, {
      headers: {
        'x-access-token': localStorage.getItem('token')
      }
    });

    const blob = await data.blob();

    // Creating new object of file
    const fileURL = window.URL.createObjectURL(blob);

    // Setting various property values
    let alink = document.createElement("a");
    alink.href = fileURL;
    alink.download = 'projectunit.csv';
    alink.click();

    return;
  }

  async import(projectId, file) {
    const formData = new FormData();
    formData.append('csv', file);

    const data = await fetch(`${process.env.REACT_APP_API_URL}/projectunits/project/${projectId}/import`, {
      method: 'POST',
      headers: {
        'x-access-token': localStorage.getItem('token')
      },
      body: formData
    });
    return await data.json();
  }

  async addUnitCustomer(puid, cid) {
    const data = await fetch(`${process.env.REACT_APP_API_URL}/projectunitcustomers`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('token')
      },
      body: JSON.stringify({ projectUnitId: puid, customerId: cid })
    });
    return await data.json();
  }

  async updateUnitCustomer(puid, cid, orderNumber) {
    const data = await fetch(`${process.env.REACT_APP_API_URL}/projectunitcustomers/${puid}/${cid}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('token')
      },
      body: JSON.stringify({ orderNumber: orderNumber })
    });
    return await data.json();
  }

  async deleteUnitCustomer(puid, cid) {
    const data = await fetch(`${process.env.REACT_APP_API_URL}/projectunitcustomers/${puid}/${cid}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('token')
      }
    });
    return;
  }

  async addSection(puid, name, length, width) {
    const data = await fetch(`${process.env.REACT_APP_API_URL}/projectunitsections`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('token')
      },
      body: JSON.stringify({ projectUnitId: puid, name: name, length: length, width: width })
    });
    return await data.json();
  }

  async deleteSection(sid) {
    const data = await fetch(`${process.env.REACT_APP_API_URL}/projectunitsections/${sid}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('token')
      }
    });
    return;
  }

  async addUpdateCommonArea(record) {
    const data = await fetch(`${process.env.REACT_APP_API_URL}/projectunitcommonareas`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('token')
      },
      body: JSON.stringify(record)
    });
    return await data.json();
  }

  async deleteCommonArea(puid, caid) {
    const data = await fetch(`${process.env.REACT_APP_API_URL}/projectunitcommonareas/${puid}/${caid}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('token')
      }
    });
    return await data.json();
  }

  getAllUnits(project, sectionId, searchText = '', incudeSubSections = true) {
    let units = project.ProjectUnits.filter(unit => unit.projectSectionId === sectionId);

    if (incudeSubSections) {
      project.ProjectSections.filter(section => section.projectSectionId === sectionId).forEach(section => {
        units.push(...this.getAllUnits(project, section.id, searchText, incudeSubSections));
      });
    }

    units = units.filter(unit => unit.name?.toLowerCase().includes(searchText.toLowerCase()) || unit.details?.toLowerCase().includes(searchText.toLowerCase()));

    return units.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
  }

}

module.exports = new ProjectUnit();