import { useState } from "react";
import { ChevronDown, ChevronRight, ExclamationTriangleFill, Pencil } from "react-bootstrap-icons";
import CommonAreaTree from "../CommonAreaTree";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/esm/Col";

export default (props) => {
    const [expand, setExpand] = useState(!props.data.id);
    const [level, setLevel] = useState(parseFloat(props.level || 0));

    const onNodeClick = (item) => {
        setExpand(!expand);
        //props.onSelectionChanged(item);
    }

    const onEditClick = (unitId, commonAreaId, value) => {
        props.onEditClick(unitId, commonAreaId, value);
    }

    // return (
    //     <div style={{ textAlign: 'left', color: 'white' }}><pre>
    //             {JSON.stringify(props.data, null, 2)}
    //         </pre></div>
    // );

    return (
        <>
            <tr className="user-select-none" style={{ paddingLeft: 20 }} onClick={() => onNodeClick(props.data)}>
                <td style={{ paddingLeft: props.level * 20 }}>{props.data.type !== 'unit' && expand ? <ChevronDown /> : <ChevronRight />} {props.data.name}</td>
                <td className="number">{props.data.carpetArea}</td>
                {props.data.commonAreas && props.data.commonAreas.map(ca =>
                    <td className="number" key={ca.id}>
                        <Row style={{ display: 'flex', alignItems: 'center' }}>
                            <Col lg="auto">
                                {props.data.type !== 'project' && ca.isEdited && <ExclamationTriangleFill color="yellow" title='Value has been edited'/>}
                                {props.data.type === 'project' && ca.isEdited && ca.area !== ca.originalArea && <ExclamationTriangleFill color="red" title={`Expected: ${ca.originalArea}`}/>}
                            </Col>
                            <Col lg>
                                {ca.area}
                            </Col>
                            <Col lg="1">
                                {props.data.type === 'unit' &&
                                    <div onClick={() => onEditClick(props.data.id, ca.id, ca.area)}><Pencil /></div>
                                }
                            </Col>
                        </Row>
                    </td>
                )}
            </tr>

            {expand && props.data.projectSections && props.data.projectSections.length > 0 && props.data.projectSections.map((item, index) => (
                <CommonAreaTree {...props} key={index} data={item} dataGroup={props.projectSections} level={level + 1} />
            ))}
            {expand && props.data.sectionUnits && props.data.sectionUnits.length > 0 && props.data.sectionUnits.map((item, index) => (
                <CommonAreaTree {...props} key={index} data={item} dataGroup={props.sectionUnits} level={level + 1} />
            ))}
        </>
    );
}

