import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Badge from 'react-bootstrap/Badge';
import Project from '../../services/project';
import ProjectUnit from '../../services/projectUnit';
import { ArrowRepeat, Download, PlusLg, Upload } from 'react-bootstrap-icons';
import SearchBar from '../SearchBar';
import Content from '../Content';
import AlertBox from '../AlertBox';
import LoadingPage from '../LoadingPage';
import FormButton from '../FormButton';
import CellDocumentLinkRenderer from '../CellDocumentLinkRenderer';

import { AgGridReact } from 'ag-grid-react'; // AG Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import Utils from '../../utils';
import ProjectSection from '../../services/projectSection';
import FolderTree from '../FolderTree';

export default function PageProjectUnits() {
    const [projectId, setProjectId] = useState(useParams().projectId);
    const [reload, setReload] = useState(true);
    const [alert, setAlert] = useState(null);
    const [projectSectionList, setProjectSectionList] = useState({});
    const [projectUnitList, setProjectUnitList] = useState([]);
    const [selectedSectionId, setSelectedSectionId] = useState(null);
    const [project, setProject] = useState({});
    const [searchText, setSearchText] = useState('');
    const [showImport, setShowImport] = useState(false);
    const [importResponseAlert, setImportResponseAlert] = useState(null);
    const mounted = useRef(true);

    const [submitting, setSubmitting] = useState(false);

    const [loading, setLoading] = useState(true);
    const [projectSectionListLoading, setProjectSectionListLoading] = useState(true);

    const [colDefs, setColDefs] = useState([
        {
            field: 'name',
            dndSource: true,
            cellRenderer: CellDocumentLinkRenderer,
            cellRendererParams: (params) => { return { onClick: () => onProjectUnitClicked(params.data.id), title: params.data.name } }
        },
        { field: 'type' },
        // {field: 'floor'},
        // {field: 'carpetArea'},
        // {field: 'builtUpArea'},
        // {field: 'superBuiltUpArea'},
        { field: 'status' },
    ]);
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            comparator: Utils.defaultComparator,
        };
    }, []);

    useEffect(() => {
        setLoading(projectSectionListLoading)
    }, [projectSectionListLoading]);

    useEffect(() => {
        setSubmitting(false);
    }, [alert]);

    useEffect(() => { document.title = "Project Units"; }, []);
    useEffect(() => {
        window.addEventListener("focus", () => setReload(true))
        return () => { window.removeEventListener("focus", () => setReload(true)); }
    }, []);

    useEffect(() => {
        mounted.current = true;
        if (projectSectionList.length && !reload) {
            return;
        }

        Project.getById(projectId)
            .then(item => {
                if (mounted.current) {
                    setProject(item);
                    document.title = item.name;
                    setProjectSectionList(ProjectSection.getSectionTree(item));
                    setReload(false);
                }
            });

        return () => mounted.current = false;
    }, [reload]);

    useEffect(() => {
        if (project && project.ProjectUnits) {
            setProjectUnitList(ProjectUnit.getAllUnits(project, selectedSectionId, searchText, false));
        }

        setProjectSectionListLoading(false);
    }, [reload, selectedSectionId]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            setReload(true);
        }, 1000);

        return () => clearTimeout(delayDebounceFn);
    }, [searchText]);

    const onProjectUnitClicked = (id) => {
        window.location.href = `/projects/${projectId}/units/${id}`
    }

    const onNewClicked = () => {
        window.location.href = `/projects/${projectId}/units/0/${selectedSectionId ?? ''}`
    }

    const uploadCsvFile = async (e) => {
        setSubmitting(true);
        setShowImport(false);
        setImportResponseAlert(await ProjectUnit.import(projectId, e.target.files[0]));
        setSubmitting(false);
        e.target.value = '';
        setReload(true);
    }

    const onSectionChange = (section) => {
        setSelectedSectionId(section.id);
        setReload(true);
    }

    const onDrop = (unitId, sectionId) => {
        setSubmitting(true);

        ProjectUnit.getById(unitId)
            .then(unit => {
                if (unit) {
                    unit.projectSectionId = sectionId;
                    ProjectUnit.addUpdate(unit).then(() => {
                        setReload(true);
                        setAlert({ isError: false, message: `${unit.name} moved successfully.` });
                    });
                }
            });
    }

    const onAddSection = (section) => {
        let val = prompt('Edit section name');

        if (val) {
            setSubmitting(true);
            ProjectSection.addUpdate({ name: val, projectId: projectId, projectSectionId: section.id }).then((item) => {
                if (item.error) {
                    setAlert({ isError: true, message: item.error });
                    return;
                }
                setReload(true);
                setAlert({ isError: false, message: `New section created successfully.` });
                setSelectedSectionId(item.id);
            });
        }
    }

    const onEditSection = (section) => {
        let val = prompt('Edit section name', section.name);

        if (val && val !== section.name) {
            console.log(val);

            setSubmitting(true);
            ProjectSection.getById(section.id)
                .then((item) => {
                    if (item) {
                        if (item.error) {
                            setAlert({ isError: true, message: item.error });
                            return;
                        }
                        item.name = val;
                        ProjectSection.addUpdate(item)
                            .then((item) => {
                                if (item.error) {
                                    setAlert({ isError: true, message: item.error });
                                    return;
                                }
                                setReload(true);
                                setAlert({ isError: false, message: `Section updated successfully.` });
                            });
                    }
                });
        }
    }

    const onDeleteSection = (section) => {
        if (section.projectSections && section.projectSections.length > 0) {
            setAlert({ isError: true, message: `Please delete all sub-sections before deleting this section.` });
            return;
        }

        const units = ProjectUnit.getAllUnits(project, section.id, searchText, false);
        if (units && units.length > 0) {
            setAlert({ isError: true, message: `Please delete all units before deleting this section.` });
            return;
        }

        if (window.confirm(`Are you sure you want to delete section ${section.name}?`)) {
            setSubmitting(true);
            ProjectSection.getById(section.id)
                .then((item) => {
                    if (item) {
                        if (item.error) {
                            setAlert({ isError: true, message: item.error });
                            return;
                        }
                        ProjectSection.delete(item).then(() => {
                            setSelectedSectionId(section.projectSectionId);
                            setReload(true);
                            setAlert({ isError: false, message: `Section deleted successfully.` });
                        });
                    }
                });
        }
    }

    return (
        <LoadingPage loading={loading} submitting={submitting}>
            <SearchBar>
                <Row style={{ display: 'flex', alignItems: 'center' }}>
                    <Col sm="auto"><Badge bg="danger" style={{ fontSize: '1.2rem' }}>{projectUnitList.length}</Badge></Col>
                    <Col sm><Form.Control type="search" placeholder="Search" aria-label="Search project" aria-describedby="basic-addon2" value={searchText} onChange={(e) => setSearchText(e.target.value)} /></Col>
                    <Col sm="auto"><Button variant="outline-success" onClick={() => onNewClicked()} title='Add new'><PlusLg size={30} /></Button></Col>
                    {/* <Col sm="auto"><Button variant="outline-primary" onClick={() => ProjectUnit.export(projectId).then(() => { })} title='Download'><Download size={30} /></Button></Col>
                    <Col sm="auto"><Button variant="outline-primary" onClick={() => setShowImport(true)} title='Upload'><Upload size={30} /></Button></Col> */}
                    <Col sm="auto"><FormButton variant="outline-secondary" onClick={() => setReload(true)} title='Refresh' loading={reload}><ArrowRepeat size={30} /></FormButton></Col>
                </Row>
            </SearchBar>
            <Content>
                <Row>
                    <Col lg={4}>
                        {projectSectionList &&
                            <Card>
                                <Card.Body>
                                    <FolderTree data={projectSectionList} dataGroup='projectSections' onSelectionChanged={onSectionChange} selectedId={selectedSectionId} onDrop={onDrop} onAdd={onAddSection} onEdit={onEditSection} onDelete={onDeleteSection} />
                                </Card.Body>
                            </Card>}
                    </Col>
                    <Col lg>
                        <div className="ag-theme-quartz" style={{ height: '600px' }}>
                            <AgGridReact
                                defaultColDef={defaultColDef}
                                columnDefs={colDefs}
                                // domLayout="autoHeight"
                                suppressScrollOnNewData='true'
                                rowData={projectUnitList}
                                rowDragManaged={true}
                            />
                        </div>
                    </Col>
                </Row>
            </Content>

            {alert &&
                <AlertBox isError={alert.isError} onClose={() => setAlert(null)}>
                    {alert.message}
                </AlertBox>}

            {showImport &&
                <AlertBox isError={false} onClose={() => setShowImport(false)}>
                    <Form.Control type="file" name="csv" accept='.csv' onChange={(e) => uploadCsvFile(e)} disabled={submitting} />
                </AlertBox>}

            {importResponseAlert &&
                <AlertBox isError={false} onClose={() => setImportResponseAlert(null)}>
                    Import complete
                </AlertBox>}
        </LoadingPage>
    );
}