import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { BoxArrowRight, Gear, FileEarmarkText, People, Buildings, Globe, Person, PersonWorkspace, SuitcaseLg } from 'react-bootstrap-icons';
import './index.css';
import User from '../../services/user';

export default () => {
    const location = useLocation();
    const activeKey = location.pathname.replaceAll('/', '');

    const [user, setUser] = useState({});
    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        if (localStorage.getItem('token')) {
            User.getById('me').then(data => {
                setUser(data);
                setIsAdmin(data.role === 'Admin');
            });
        } else if (location.pathname !== '/login') {
            window.location.href = "/login";
        }
    }, []);

    const logout = () => {
        localStorage.setItem('token', '');
        window.location.href = "/login";
    }
    return (
        <>
            {localStorage.getItem('token') && location.pathname !== '/' && location.pathname !== '/login' &&
                <Navbar expand="sm" fixed='top' data-bs-theme="dark" className='Header' style={{ paddingLeft: '2rem', paddingRight: '2rem', backgroundColor: 'black', fontWeight: 'bold' }}>
                    <Navbar.Brand href="/projects"> </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto" activeKey={activeKey}>
                            <Nav.Link eventKey="projects" href="/projects"><Buildings size={20} /> Projects</Nav.Link>
                            <Nav.Link eventKey="customers" href="/customers"><People size={20} /> Customers</Nav.Link>
                            <Nav.Link eventKey="brokers" href="/brokers"><SuitcaseLg size={20} /> Brokers</Nav.Link>
                            <Nav.Link eventKey="templates" href="/templates"><FileEarmarkText size={20} /> Templates</Nav.Link>
                            <Nav.Link eventKey="directors" href="/directors"><PersonWorkspace size={20} /> Directors</Nav.Link>
                            <Nav.Link eventKey="global" href="/global"><Globe size={20} /> Global</Nav.Link>
                        </Nav>

                        <Nav activeKey={location.pathname}>
                            {isAdmin && <Nav.Link eventKey="users" href="/users"><People size={20} /> Users</Nav.Link>}
                            <NavDropdown title={user.name || user.username} id="basic-nav-dropdown">
                                <NavDropdown.Item href={`/users/${user.id}`}><Person size={20} /> Profile</NavDropdown.Item>
                                {isAdmin && <NavDropdown.Item href="/settings"><Gear size={20} /> Settings</NavDropdown.Item>}
                                <NavDropdown.Divider />
                                <NavDropdown.Item onClick={logout}><BoxArrowRight size={20} /> Logout</NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            }
        </>
    )
}