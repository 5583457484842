class Project {
  async get(filter) {
    const data = await fetch(`${process.env.REACT_APP_API_URL}/projects?q=${filter}`, {
      headers: {
        'x-access-token': localStorage.getItem('token')
      }
    });
    return await data.json();
  }

  async getById(id) {
    if (id === '0') {
      return {};
    }
    const data = await fetch(`${process.env.REACT_APP_API_URL}/projects/${id}`, {
      headers: {
        'x-access-token': localStorage.getItem('token')
      }
    });
    return await data.json();
  }

  async addUpdate(record) {
    const data = await fetch(`${process.env.REACT_APP_API_URL}/projects/${record.id || ''}`, {
      method: record.id ? 'PUT' : 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('token')
      },
      body: JSON.stringify(record)
    });
    return await data.json();
  }

  async delete(record) {
    const data = await fetch(`${process.env.REACT_APP_API_URL}/projects/${record.id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('token')
      }
    });
    return await data.json();
  }

  async getImage(id) {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/files/${id}/projectImage.png`, {
      headers: {
        'x-access-token': localStorage.getItem('token')
      }
    });
    const imageBlob = await res.blob();
    const imageObjectURL = URL.createObjectURL(imageBlob);
    return imageObjectURL;
  }

  async setImage(id, file) {
    const formData = new FormData();
    formData.append('file', file);

    const data = await fetch(`${process.env.REACT_APP_API_URL}/files/${id}/projectImage`, {
      method: 'POST',
      headers: {
        'x-access-token': localStorage.getItem('token')
      },
      body: formData
    });
    return await data.json();
  }

  async addDirector(id, directorId) {
    const data = await fetch(`${process.env.REACT_APP_API_URL}/projectdirectors`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('token')
      },
      body: JSON.stringify({ projectId: id, directorId: directorId })
    });
    return await data.json();
  }

  async deleteDirector(id, directorId) {
    const data = await fetch(`${process.env.REACT_APP_API_URL}/projectdirectors/${id}/${directorId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('token')
      }
    });
    return;
  }

  async addCommonArea(pid, commonArea) {
    const data = await fetch(`${process.env.REACT_APP_API_URL}/projectcommonareas`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('token')
      },
      body: JSON.stringify({ projectId: pid, name: commonArea.name, area: commonArea.area })
    });
    return await data.json();
  }

  async deleteCommonArea(commonAreaId) {
    const data = await fetch(`${process.env.REACT_APP_API_URL}/projectcommonareas/${commonAreaId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('token')
      }
    });
    return;
  }
}

module.exports = new Project();