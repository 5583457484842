import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Project from '../../services/project';
import ProjectUnit from '../../services/projectUnit';
import { ArrowRepeat, Plus, PlusLg } from 'react-bootstrap-icons';
import SearchBar from '../SearchBar';
import Content from '../Content';
import AlertBox from '../AlertBox';
import LoadingPage from '../LoadingPage';
import FormButton from '../FormButton';

import ProjectSection from '../../services/projectSection';
import Table from 'react-bootstrap/Table';
import CommonAreaTree from '../CommonAreaTree';

export default function PageProjectCommonAreas() {
    const [projectId, setProjectId] = useState(useParams().projectId);
    const [reload, setReload] = useState(true);
    const [alert, setAlert] = useState(null);
    const [projectSectionList, setProjectSectionList] = useState({});
    const [project, setProject] = useState({});
    const [searchText, setSearchText] = useState('');
    const [showImport, setShowImport] = useState(false);
    const [importResponseAlert, setImportResponseAlert] = useState(null);
    const mounted = useRef(true);

    const [submitting, setSubmitting] = useState(false);

    const [loading, setLoading] = useState(true);
    const [projectSectionLoading, setProjectSectionLoading] = useState(true);

    useEffect(() => {
        setLoading(projectSectionLoading)
    }, [projectSectionLoading]);

    useEffect(() => {
        setSubmitting(false);
    }, [alert]);

    useEffect(() => { document.title = "Common Area"; }, []);
    useEffect(() => {
        window.addEventListener("focus", () => setReload(true))
        return () => { window.removeEventListener("focus", () => setReload(true)); }
    }, []);

    useEffect(() => {
        mounted.current = true;
        if (projectSectionList.length && !reload) {
            return;
        }

        Project.getById(projectId)
            .then(item => {
                if (mounted.current) {
                    setProject(item);
                    const sectionTree = ProjectSection.getSectionTree(item);
                    setProjectSectionList(sectionTree);
                    setProjectSectionLoading(false);
                    setReload(false);
                }
            });

        return () => mounted.current = false;
    }, [reload]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            setReload(true);
        }, 1000);

        return () => clearTimeout(delayDebounceFn);
    }, [searchText]);

    const uploadCsvFile = async (e) => {
        setSubmitting(true);
        setShowImport(false);
        setImportResponseAlert(await ProjectUnit.import(projectId, e.target.files[0]));
        setSubmitting(false);
        e.target.value = '';
        setReload(true);
    }

    const onEditClick = (unitId, commonAreaId, value) => {
        console.log('unitId', unitId, 'commonAreaId', commonAreaId, 'value', value);

        let val = prompt('Set custom area', value);

        console.log('val', val);
        
        if (val === value) {
            return;
        }
        
        if (val) {
            setSubmitting(true);
            ProjectUnit.addUpdateCommonArea({ projectUnitId: unitId, projectCommonAreaId: commonAreaId, area: val }).then((item) => {
                if (item.error) {
                    setAlert({ isError: true, message: item.error });
                    return;
                }
                setReload(true);
                setAlert({ isError: false, message: `New area was saved successfully.` });
            });
        } else if (val === '') {
            setSubmitting(true);
            ProjectUnit.deleteCommonArea(unitId,commonAreaId).then((item) => {
                if (item.error) {
                    setAlert({ isError: true, message: item.error });
                    return;
                }
                setReload(true);
                setAlert({ isError: false, message: `Area was reset successfully.` });
            });
        }
    }

    return (
        <LoadingPage loading={loading} submitting={submitting}>
            <SearchBar>
                <Row style={{ display: 'flex', alignItems: 'center' }}>
                    <Col sm></Col>
                    {/* <Col sm="auto"><Button variant="outline-primary" onClick={() => ProjectUnit.export(projectId).then(() => { })} title='Download'><Download size={30} /></Button></Col>
                    <Col sm="auto"><Button variant="outline-primary" onClick={() => setShowImport(true)} title='Upload'><Upload size={30} /></Button></Col> */}
                    <Col sm="auto"><FormButton variant="outline-secondary" onClick={() => setReload(true)} title='Refresh' loading={reload}><ArrowRepeat size={30} /></FormButton></Col>
                </Row>
            </SearchBar>
            <Content>
                <Row>
                    <Col lg>
                        <Row>
                            <Col lg>
                                <Table bordered hover className='commonarea-table'>
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>Carpet Area</th>
                                            {project.ProjectCommonAreas && project.ProjectCommonAreas.map(ca => <th key={ca.id}>{ca.name}</th>)}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <CommonAreaTree data={projectSectionList} level='0' onEditClick={onEditClick} />
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Content>

            {alert &&
                <AlertBox isError={alert.isError} onClose={() => setAlert(null)}>
                    {alert.message}
                </AlertBox>}

            {showImport &&
                <AlertBox isError={false} onClose={() => setShowImport(false)}>
                    <Form.Control type="file" name="csv" accept='.csv' onChange={(e) => uploadCsvFile(e)} disabled={submitting} />
                </AlertBox>}

            {importResponseAlert &&
                <AlertBox isError={false} onClose={() => setImportResponseAlert(null)}>
                    Import complete
                </AlertBox>}
        </LoadingPage>
    );
}