import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Project from '../../services/project';
import Customer from '../../services/customer';
import ProjectUnit from '../../services/projectUnit';
import { ArrowsMove, Copy, CurrencyRupee, Floppy, Newspaper, PlusLg, Trash } from 'react-bootstrap-icons';
import FormInput from '../../components/FormInput';
import FormSelect from '../../components/FormSelect';
import SearchBar from '../SearchBar';
import Content from '../Content';
import AlertBox from '../AlertBox';
import LoadingPage from '../LoadingPage';

import CommentList from '../CommentList';
import FileList from '../FileList';
import FormButton from '../FormButton';
import CellDocumentLinkRenderer from '../CellDocumentLinkRenderer';

import { AgGridReact } from 'ag-grid-react'; // AG Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import Utils from '../../utils';
import './index.css';
import ProjectSection from '../../services/projectSection';

export default function PageProjectUnitEdit() {
    const mounted = useRef(true);
    const [projectId, setProjectId] = useState(useParams().projectId);
    const [projectSectionId, setProjectSectionId] = useState(useParams().projectSectionId);
    const [disableDelete, setDisableDelete] = useState(false);
    const [id, setId] = useState(useParams().id);
    const [isDirty, setIsDirty] = useState(false);
    const [reload, setReload] = useState(true);
    const [alert, setAlert] = useState(null);
    const [projectUnit, setProjectUnit] = useState({});
    const [projectSectionPath, setProjectSectionPath] = useState('');
    const [customers, setCustomers] = useState([]);
    const [showCustomerList, setShowCustomerList] = useState(false);
    const [projectUnitTypes, setProjectUnitTypes] = useState([]);
    const [projectUnitStatuses, setProjectUnitStatuses] = useState([]);
    const [sectionName, setSectionName] = useState('');
    const [sectionLength, setSectionLength] = useState('');
    const [sectionWidth, setSectionWidth] = useState('');
    const [showCopyWizard, setShowCopyWizard] = useState(false);
    const [newUnitName, setNewUnitName] = useState('');
    const [newUnitNameList, setNewUnitNameList] = useState([]);

    const [submitting, setSubmitting] = useState(false);

    const [loading, setLoading] = useState(true);
    const [projectUnitLoading, setProjectUnitLoading] = useState(true);

    const [colDefsCustomers, setColDefsCustomers] = useState([
        {
            headerName: 'Customers',
            field: 'name',
            cellRenderer: CellDocumentLinkRenderer,
            cellRendererParams: (params) => { return { onClick: () => onAddCustomer(params.data.id), title: params.data.name } }
        }
    ]);
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            comparator:  Utils.defaultComparator,
        };
    }, []);

    useEffect(() => {
        setLoading(projectUnitLoading)
    }, [projectUnitLoading]);

    useEffect(() => {
        ProjectUnit.getTypes()
            .then(items => {
                setProjectUnitTypes(items);
            });
        ProjectUnit.getStatuses()
            .then(items => {
                setProjectUnitStatuses(items);
            });
    }, []);

    useEffect(() => {
        setSubmitting(false);
        setShowCustomerList(false);
    }, [alert]);

    useEffect(() => {
        if (showCustomerList) {
            Customer.get('')
                .then(items => {
                    setCustomers(items);
                });
        }
    }, [showCustomerList]);

    useEffect(() => { document.title = "Edit Project Unit"; }, []);

    useEffect(() => {
        mounted.current = true;
        if (!reload) {
            return;
        }

        if (id === '0') {
            Project.getById(projectId)
                .then(p => {
                    if (mounted.current) {
                        projectUnit.Project = { name: p.name };
                        projectUnit.projectId = p.id;
                        projectUnit.projectSectionId = projectSectionId;
                        
                        ProjectSection.getPathById(projectSectionId)
                            .then(path => {
                                setProjectSectionPath(path);
                            });
                        

                        setProjectUnit(projectUnit);
                        setProjectUnitLoading(false);
                        setReload(false);
                        setSubmitting(false);
                    }
                });
        } else {
            ProjectUnit.getById(id)
                .then(item => {
                    if (mounted.current) {
                        if (item.Customers.length > 0 && item.status === 'Available') {
                            setIsDirty(true);
                            item.status = 'Booked';
                        }
                        if (item.Customers.length === 0 && item.status === 'Booked') {
                            setIsDirty(true);
                            item.status = 'Available';
                        }

                        ProjectSection.getPathById(item.projectSectionId)
                            .then(path => {
                                    setProjectSectionPath(path);
                                    setProjectUnit(item);
                                });
                        
                        setDisableDelete(item.Customers && item.Customers.length > 0);
                        setProjectUnitLoading(false);
                        setReload(false);
                        setSubmitting(false);
                    }
                })
                .catch(e => {
                    //console.log(e);
                    window.location.href = "/notfound";
                });
        }

        return () => mounted.current = false;
    }, [reload]);

    useEffect(() => {
        if (isDirty) {
            setAlert(null);
            window.addEventListener("beforeunload", beforeUnloadHandler);
        }
        return () => {
            window.removeEventListener("beforeunload", beforeUnloadHandler);
        }
    }, [isDirty]);

    const beforeUnloadHandler = (event) => {
        event.preventDefault();
        event.returnValue = true;
    };

    const onFieldChanged = (field, value) => {
        if (projectUnit) {
            setIsDirty(true);
            setProjectUnit({ ...projectUnit, [field]: value });
        }
    }

    const onSave = () => {
        setSubmitting(true);
        ProjectUnit.addUpdate(projectUnit)
            .then(item => {
                if (mounted.current) {
                    if (item.error) {
                        return setAlert({ message: item.error, isError: true });
                    }

                    setId(item.id);
                    setIsDirty(false);
                    setProjectUnit(item);
                    setAlert({ message: `Saved`, isError: false });
                    setReload(true);
                }
            });
    }

    const onDelete = () => {
        if (window.confirm("Are you sure you want to delete?") === true) {
            setSubmitting(true);
            setIsDirty(false);
            ProjectUnit.delete(projectUnit).then(item => {
                if (item.error) {
                    setAlert({ message: 'Unable to delete', isError: true });
                    return;
                }
                window.location.href = `/projects/${projectId}/units`
            });
        } else {
            // Do nothing
        }
    }

    const onCopy = () => {
        if (isDirty) {
            return setAlert({ message: 'Please save before copying', isError: true });
        }

        if (window.confirm("Are you sure you want to copy?") === true) {
            setSubmitting(true);
            ProjectUnit.copy(projectUnit).then(item => {
                if (item.error) {
                    setAlert({ message: item.error, isError: true });
                    return;
                }
                window.location.href = `/projects/${projectId}/units/${item.id}`
            });
        } else {
            // Do nothing
        }
    }

    const onRemoveCustomerClick = (custId) => {
        if(isDirty) {
            return setAlert({ message: 'Please save before removing customer', isError: true });
        }

        if (window.confirm("Are you sure you want to delete?") === true) {
            setSubmitting(true);
            ProjectUnit.deleteUnitCustomer(projectUnit.id, custId)
                .then(() => {
                    setAlert({ message: 'Customer removed', isError: false });
                    setReload(true);
                });
        } else {
            // Do nothing
        }
    }

    const onAddCustomer = (cid) => {
        if(isDirty) {
            return setAlert({ message: 'Please save before adding customer', isError: true });
        }

        setSubmitting(true);
        ProjectUnit.addUnitCustomer(id, cid)
            .then((item) => {
                if (item.error) {
                    return setAlert({ message: item.error, isError: true });
                }

                setAlert({ message: 'Customer added', isError: false });
                setReload(true);
            });
    }

    const handleDragStart = (event, customer) => {
        event.dataTransfer.setData("text/html", customer.id);
    };

    const handleDragOver = (event, customer) => {
        event.preventDefault();
    };

    const handleDragEnter = (event, customer) => {
        event.preventDefault();
    };

    const handleDragLeave = (event, customer) => {
        event.preventDefault();
    };

    const handleDrop = (event, customer) => {
        event.preventDefault();
        const customerId = event.dataTransfer.getData("text/html");

        if(isDirty) {
            return setAlert({ message: 'Please save before moving customer', isError: true });
        }

        if (window.confirm("Are you sure you want to move the customer?") === true) {
            ProjectUnit.updateUnitCustomer(projectUnit.id, customerId, customer.ProjectUnitCustomer.orderNumber)
                .then(() => {
                    setAlert({ message: 'Customer moved', isError: false });
                    setReload(true);
                });
        }
    };

    const addSection = () => {
        if(isDirty) {
            return setAlert({ message: 'Please save before adding a room or area', isError: true });
        }

        if (sectionName === '') {
            return setAlert({ message: 'Please enter a room or area name', isError: true });
        }

        if (sectionLength === 0) {
            return setAlert({ message: 'Please enter a length', isError: true });
        }

        if (sectionWidth === 0) {
            return setAlert({ message: 'Please enter a width', isError: true });
        }

        setSubmitting(true);
        ProjectUnit.addSection(projectUnit.id, sectionName, sectionLength, sectionWidth)
            .then(item => {
                if (mounted.current) {
                    if (item.error) {
                        return setAlert({ message: item.error, isError: true });
                    }

                    setSectionName('');
                    setSectionLength('');
                    setSectionWidth('');
                    setAlert({ message: `Saved`, isError: false });
                    setReload(true);
                }
            });
    }

    const deleteSection = (sid) => {
        if(isDirty) {
            return setAlert({ message: 'Please save before deleting a room or area', isError: true });
        }

        if (window.confirm("Are you sure you want to delete?") === true) {
            setSubmitting(true);
            ProjectUnit.deleteSection(sid).then(() => {
                setAlert({ message: 'Section deleted', isError: false });
                setReload(true);
            });
        } else {
            // Do nothing
        }
    }

    const toFloat = (value) => {
        var val = value;
        if (isNaN(val) || !isFinite(val) || val === '' || val === null || val === undefined || val === 'null' || val === 'undefined' || val === 'NaN' || val === 'Infinity' || val === '-Infinity' || val === '-NaN') {
            val = 0;
        }
        return parseFloat(val);
    }

    const toFixed = (value) => {
        return toFloat(value).toFixed(2);
    }

    const addNewUnitNameToList = (name) => {
        if (name === '') {
            return setAlert({ message: 'Please enter a name', isError: true });
        }

        if (name === projectUnit.name) {
            return setAlert({ message: 'Please enter a new name', isError: true });
        }

        // check if name exists in the list
        if (newUnitNameList.includes(name)) {
            return setAlert({ message: 'Name already exists in the list', isError: true });
        }

        setNewUnitNameList([...newUnitNameList, name]);
    }

    return (
        <LoadingPage loading={loading} submitting={submitting}>
            <SearchBar>
                <Row style={{ display: 'flex', alignItems: 'center' }}>
                    <Col sm style={{ display: 'flex', alignItems: 'center', fontStyle: 'bold' }}>
                        <a href='/projects' style={{ paddingRight: '5px', paddingLeft: '5px' }}>Projects</a> /
                        <a href={`/projects/${projectId}/units`} style={{ paddingRight: '5px', paddingLeft: '5px' }}>{projectUnit?.Project?.name}</a> /
                        <span style={{ paddingRight: '5px', paddingLeft: '5px' }}>{projectUnit.name}</span>
                    </Col>
                    {id !== '0' && !disableDelete && <Col sm="auto"><Button variant="outline-danger" onClick={() => onDelete()} title='Delete' disabled={submitting}><Trash size={30} /></Button></Col>}
                    <Col sm="auto"><Button variant="outline-primary" onClick={() => {setNewUnitName(projectUnit.name); setShowCopyWizard(true)}} title='Copy' disabled={submitting}><Copy size={30} /></Button></Col>
                    <Col sm="auto"><Button variant="outline-success" onClick={() => onSave()} title='Save' disabled={submitting}><Floppy size={30} /></Button></Col>
                </Row>
            </SearchBar>
            <Content>
                <Row>
                    <Col>
                        <Row>
                            <Col>
                                <FormInput value={projectSectionPath} label='Section' disabled={true} onChange={() => setProjectSectionPath(projectSectionPath)} />
                            </Col>
                            <Col lg={4}>
                                    <FormSelect value={projectUnit.status} indexfield='key' label='Status' field='value' options={projectUnitStatuses} onChange={(e) => onFieldChanged('status', e.target.value)} />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg>
                                <FormInput value={projectUnit.name || ''} label='Name' onChange={(e) => onFieldChanged('name', e.target.value)} />
                            </Col>
                            <Col lg>
                                <FormInput value={projectUnit.floor || ''} label='Floor' onChange={(e) => onFieldChanged('floor', e.target.value)} />
                            </Col>
                            <Col lg>
                                <FormSelect value={projectUnit.type} indexfield='key' label='Type' field='value' options={projectUnitTypes} onChange={(e) => onFieldChanged('type', e.target.value)} />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg>
                                <FormInput type='number' value={projectUnit.carpetArea || ''} label='Carpet Area' onChange={(e) => onFieldChanged('carpetArea', e.target.value)} />
                            </Col>
                            <Col lg>
                            <FormInput type='number' value={projectUnit.reraCarpetArea || ''} label='RERA Carpet Area' onChange={(e) => onFieldChanged('reraCarpetArea', e.target.value)} />
                            </Col>
                            <Col lg>
                                <FormInput type='number' value={projectUnit.builtUpArea || ''} label='Built Up Area' onChange={(e) => onFieldChanged('builtUpArea', e.target.value)} />
                            </Col>
                            <Col lg>
                                <FormInput type='number' value={projectUnit.superBuiltUpArea || ''} label='Super Built Up Area' onChange={(e) => onFieldChanged('superBuiltUpArea', e.target.value)} />
                            </Col>
                        </Row>
                        <FormInput value={projectUnit.details || ''} label='Details' onChange={(e) => onFieldChanged('details', e.target.value)} as='textarea' style={{ height: '100px' }} />
                        

                        {projectUnit && projectUnit.id && 
                            <Row>
                                <Col lg>
                                    <Table bordered hover className='section-table'>
                                        <thead>
                                            <tr>
                                                <th>Room/Area</th>
                                                <th>Length (ft)</th>
                                                <th>Width (ft)</th>
                                                <th>Area (sq.ft.)</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td><FormInput value={sectionName} onChange={(e) => setSectionName(e.target.value)} /></td>
                                                <td><FormInput type='number' value={sectionLength} onChange={(e) => setSectionLength(e.target.value)} /></td>
                                                <td><FormInput type='number' value={sectionWidth} onChange={(e) => setSectionWidth(e.target.value)} /></td>
                                                <td className='number'>{toFixed(sectionLength * sectionWidth)}</td>
                                                <td className='center'><FormButton variant='success' onClick={() => addSection()}><PlusLg  /></FormButton></td>
                                            </tr>
                                            {projectUnit.ProjectUnitSections && projectUnit.ProjectUnitSections.map((s, i) =>
                                                <tr key={i}>
                                                    <td>{s.name}</td>
                                                    <td className='number'>{toFixed(s.length)}</td>
                                                    <td className='number'>{toFixed(s.width)}</td>
                                                    <td className='number'>{toFixed(s.length * s.width)}</td>
                                                    <td className='center'><Trash className='text-danger' title='Delete' style={{ cursor: 'pointer' }} onClick={() => deleteSection(s.id)} /></td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        }
                    </Col>
                    <Col sm='3'>
                        <FileList modelId={id} modelType='projectUnit' />
                    </Col>
                    <Col sm='auto'>
                        {id !== '0' &&
                            <Row className='mb-2'>
                                <Col>
                                    <Card style={{ textAlign: 'center' }}>
                                        <Card.Header>Customer</Card.Header>
                                        <Card.Body style={{ textAlign: 'right' }}>
                                            {projectUnit.Customers && projectUnit.Customers.map((c, i) =>
                                                <Row key={i} style={{ display: 'flex', alignItems: 'center' }}>

                                                    <Col lg='auto'>{c.ProjectUnitCustomer.orderNumber}.</Col>
                                                    <Col lg>
                                                        <Card key={i} className='mb-2' style={{ backgroundColor: '#d0ae6b', cursor: 'pointer', textAlign: 'left' }}
                                                            draggable={true}
                                                            onDragStart={(e) => handleDragStart(e, c)}
                                                            onDragEnter={(e) => handleDragEnter(e, c)}
                                                            onDragLeave={(e) => handleDragLeave(e, c)}
                                                            onDragOver={(e) => handleDragOver(e, c)}
                                                            onDrop={(e) => handleDrop(e, c)}>
                                                            <Card.Body>
                                                                <Row>
                                                                    <Col sm='auto'><ArrowsMove style={{ cursor: 'move' }} className='text-dark' /></Col>
                                                                    <Col sm onClick={() => window.location.href = `/customers/${c.id}`}>{c.name}</Col>
                                                                    <Col sm='auto'><Trash className='text-danger' onClick={() => onRemoveCustomerClick(c.id)} /></Col>
                                                                </Row>
                                                            </Card.Body>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            )}
                                            <Row style={{ display: 'flex', alignItems: 'center' }}>
                                                <Col lg><FormButton variant='outline-danger' title='Soda Chitthi' onClick={() => window.location.href = `/projects/${projectId}/units/${id}/sc`}><Newspaper size={30}/></FormButton></Col>
                                                <Col lg><FormButton variant='outline-danger' title='Payments' onClick={() => window.location.href = `/projects/${projectId}/units/${id}/payments`}><CurrencyRupee size={30}/></FormButton></Col>
                                                <Col lg><FormButton variant='success' onClick={() => setShowCustomerList(true)}><PlusLg size={30} /></FormButton></Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        }
                        <CommentList modelId={id} />
                    </Col>
                </Row>
            </Content>

            {alert &&
                <AlertBox isError={alert.isError} onClose={() => setAlert(null)}>
                    {alert.message}
                </AlertBox>}

            {showCustomerList &&
                <AlertBox isError={false} onClose={() => setShowCustomerList(false)}>
                    <div className="ag-theme-quartz" style={{ height: '20rem', width: '28rem' }}>
                        <AgGridReact
                            defaultColDef={defaultColDef}
                            columnDefs={colDefsCustomers}
                            // domLayout="autoHeight"
                            suppressScrollOnNewData='true'
                            rowData={customers}
                        />
                    </div>
                </AlertBox>}

            {showCopyWizard &&
                <AlertBox isError={false} onClose={() => setShowCopyWizard(false)}>
                    <Row>
                        <Col lg>
                            Create new unit(s)
                            <br /><br />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg>
                            <FormInput value={newUnitName} onChange={(e) => setNewUnitName(e.target.value)} />
                        </Col>
                        <Col lg>
                            <FormButton variant='success' onClick={() => addNewUnitNameToList(newUnitName)}><PlusLg /></FormButton>
                        </Col>
                    </Row>
                    <br />
                    {newUnitNameList.length > 0 &&
                        <Row>
                            <Col>
                                <Table bordered hover>
                                    <thead>
                                        <tr>
                                            <th>Unit Name</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {newUnitNameList.map((n, i) =>
                                            <tr key={i}>
                                                <td>{n}</td>
                                                <td style={{textAlign: 'center'}}><Trash className='text-danger' onClick={() => setNewUnitNameList(newUnitNameList.filter((_, index) => index !== i))} style={{ cursor: 'pointer'}}/></td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    }
                </AlertBox>}
            {/* <div style={{textAlign: 'left'}}><pre>
                {JSON.stringify(project, null, 2)}
            </pre></div> */}
        </LoadingPage>
    )
}