import { useState } from "react";
import { ChevronDown, ChevronRight, Pencil, PlusSquare, Trash } from "react-bootstrap-icons";
import Button from "react-bootstrap/Button";
import FolderTree from "../FolderTree";
import "./index.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap//Col";

export default (props) => {
    const [expand, setExpand] = useState(!props.data.id);

    const onNodeClick = (item) => {
        setExpand(true);
        props.onSelectionChanged(item);
    }

    const onDoubleClick = () => {
        setExpand(!expand);
    }

    const onDragOver = (event) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = "move";
        setExpand(true);
    }

    const onDragLeave = (event) => {
        event.preventDefault();
    }

    const onDrop = (event) => {
        event.preventDefault();
        const eventData = JSON.parse(event.dataTransfer.getData("text/plain"));
        props.onDrop(eventData.id, props.data.id);
    }

    const onAddClick = (item) => {
        props.onSelectionChanged(item);
        props.onAdd(item);
        setExpand(true);
    }

    const onEditClick = (item) => {
        props.onSelectionChanged(item);
        props.onEdit(item);
    }

    const onDeleteClick = (item) => {
        props.onSelectionChanged(item);
        props.onDelete(item);
    }

    return (
        <div className="user-select-none" style={{ paddingLeft: 20 }}>
            <Row className={props.data.id === props.selectedId ? "folderTree selected" : "folderTree"} onClick={() => onNodeClick(props.data)} onDoubleClick={onDoubleClick} onDragOver={onDragOver} onDragLeave={onDragLeave} onDrop={onDrop}>
                <Col style={{ paddingTop: "5px" }} sm='auto'>{expand ? <ChevronDown /> : <ChevronRight />}</Col>
                <Col style={{ paddingTop: "6px" }} sm>{props.data.name}</Col>
                <Col sm='auto'>
                    {props.onEdit && props.data.id && <Button variant="dark-outline" onClick={() => onEditClick(props.data)}><Pencil /></Button>}
                    {props.onAdd && <Button variant="dark-outline" onClick={() => onAddClick(props.data)}><PlusSquare color="green" /></Button>}
                    {props.onDelete && props.data.id && <Button variant="dark-outline" onClick={() => onDeleteClick(props.data)}><Trash color="red" /></Button>}
                </Col>
            </Row>
            {props.data[props.dataGroup] && props.data[props.dataGroup].length > 0 &&
                <div style={{ display: expand ? "block" : "none", paddingLeft: 15 }} >
                    {
                        props.data[props.dataGroup].map((item, index) => (
                            <FolderTree {...props} key={index} data={item} dataGroup={props.dataGroup} />
                        ))
                    }
                </div>
            }
        </div>
    );
}

