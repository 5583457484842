class ProjectSection {
  async get(projectId, filter) {
    const data = await fetch(`${process.env.REACT_APP_API_URL}/projectsections/project/${projectId}?q=${filter}`, {
      headers: {
        'x-access-token': localStorage.getItem('token')
      }
    });
    return await data.json();
  }

  async getById(id) {
    if (id === '0') {
      return {};
    }
    const data = await fetch(`${process.env.REACT_APP_API_URL}/projectsections/${id}`, {
      headers: {
        'x-access-token': localStorage.getItem('token')
      }
    });
    return await data.json();
  }

  async getPathById(id) {
    if (!id) {
      return '';
    }

    const data = await fetch(`${process.env.REACT_APP_API_URL}/projectsections/${id}`, {
      headers: {
        'x-access-token': localStorage.getItem('token')
      }
    });
    const record = await data.json();
    if (!record) {
      return '';
    }
    const parent = await this.getPathById(record.projectSectionId);
    if (parent) {
      return `${parent} > ${record.name}`;
    }
    return record.name;
  }

  async addUpdate(record) {
    const data = await fetch(`${process.env.REACT_APP_API_URL}/projectsections/${record.id || ''}`, {
      method: record.id ? 'PUT' : 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('token')
      },
      body: JSON.stringify(record)
    });
    return await data.json();
  }

  async delete(record) {
    const data = await fetch(`${process.env.REACT_APP_API_URL}/projectsections/${record.id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('token')
      }
    });
    return await data.json();
  }

  toFloat(value) {
    var val = value;
    if (isNaN(val) || !isFinite(val) || val === '' || val === null || val === undefined || val === 'null' || val === 'undefined' || val === 'NaN' || val === 'Infinity' || val === '-Infinity' || val === '-NaN') {
      val = 0;
    }
    return parseFloat(val);
  }

  toFixed(value) {
    return this.toFloat(value).toFixed(2);
  }

  getTotalCarpetArea(project) {
    if (!project || !project.ProjectUnits) {
      return 0;
    }
    return this.toFixed(project.ProjectUnits.reduce((acc, unit) => this.toFloat(acc) + this.toFloat(unit.carpetArea), 0));
  }


  createTree(project, parentNodeId, list) {
    const projectSections = list.filter(item => item.projectSectionId === parentNodeId).map(item => { return { id: item.id, name: item.name, type: 'section', projectSections: [], sectionUnits: [], carpetArea: 0 } });

    if (projectSections.length) {
      projectSections.forEach(child => {
        child.projectSections = this.createTree(project, child.id, list);
        child.sectionUnits = this.getSectionUnits(project, child.id);
        child.carpetArea = this.toFixed(child.sectionUnits.reduce((acc, unit) => this.toFloat(acc) + this.toFloat(unit.carpetArea), 0) + child.projectSections.reduce((acc, section) => this.toFloat(acc) + this.toFloat(section.carpetArea), 0));
        const ratio = child.carpetArea / this.getTotalCarpetArea(project);
        //child.commonAreas = project.ProjectCommonAreas.map(item => { return { id: item.id, name: item.name, area: this.toFixed(ratio * item.area) } });

        child.commonAreas = project.ProjectCommonAreas.map(item => {
          return {
            id: item.id,
            name: item.name,
            area: this.toFixed(child.sectionUnits.reduce((acc, unit) => this.toFloat(acc) + unit.commonAreas.filter(c => c.id === item.id).reduce((acc, area) => this.toFloat(acc) + this.toFloat(area.area), 0), 0)
              + child.projectSections.reduce((acc, unit) => this.toFloat(acc) + unit.commonAreas.filter(c => c.id === item.id).reduce((acc, area) => this.toFloat(acc) + this.toFloat(area.area), 0), 0)),
            isEdited: child.sectionUnits.some(c => c.commonAreas.some(ca => ca.id === item.id && ca.isEdited)) || child.projectSections.some(c => c.commonAreas.some(ca => ca.id === item.id && ca.isEdited))
          }
        });
      });
    }
    return projectSections.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
  }

  getSectionUnits(project, selectedSectionId) {
    if (!project || !project.ProjectUnits) {
      return [];
    }

    const units = project.ProjectUnits.filter(item => item.projectSectionId === selectedSectionId).map(item => {
      const ratio = item.carpetArea / this.getTotalCarpetArea(project);
      const unit = { id: item.id, name: item.name, type: 'unit', carpetArea: item.carpetArea, commonAreas: item.ProjectCommonAreas.map(c => { return { id: c.id, name: c.name, area: c.ProjectUnitCommonArea.area, isEdited: true } }) };

      // Add mising common areas
      project.ProjectCommonAreas.filter(c => !unit.commonAreas.some(ca => ca.id === c.id)).forEach(c => {
        unit.commonAreas.push({ id: c.id, name: c.name, area: this.toFixed(ratio * c.area), isEdited: false });
      });
      
      unit.commonAreas = unit.commonAreas.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
      // unit.commonAreas = project.ProjectCommonAreas.map(item => { return { id: item.id, name: item.name, area: this.toFixed(ratio * item.area), isEdited: false } });
      return unit;
    });

    return units.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
  }

  getSectionTree(project) {
    const tree = { id: null, name: project.name, type: 'project', projectSections: this.createTree(project, null, project.ProjectSections), sectionUnits: this.getSectionUnits(project, null), carpetArea: 0, commonAreas: [] };
    tree.carpetArea = this.toFixed(tree.sectionUnits.reduce((acc, unit) => this.toFloat(acc) + this.toFloat(unit.carpetArea), 0) + tree.projectSections.reduce((acc, section) => this.toFloat(acc) + this.toFloat(section.carpetArea), 0));
    tree.commonAreas = project.ProjectCommonAreas.map(item => {
      return {
        id: item.id,
        name: item.name,
        area: this.toFixed(tree.sectionUnits.reduce((acc, unit) => this.toFloat(acc) + unit.commonAreas.filter(c => c.id === item.id).reduce((acc, area) => this.toFloat(acc) + this.toFloat(area.area), 0), 0)
          + tree.projectSections.reduce((acc, unit) => this.toFloat(acc) + unit.commonAreas.filter(c => c.id === item.id).reduce((acc, area) => this.toFloat(acc) + this.toFloat(area.area), 0), 0)),
        originalArea: this.toFixed(item.area),
        isEdited: tree.sectionUnits.some(c => c.commonAreas.some(ca => ca.id === item.id && ca.isEdited)) || tree.projectSections.some(c => c.commonAreas.some(ca => ca.id === item.id && ca.isEdited))
      }
    });
    return tree;
  }
}

module.exports = new ProjectSection();