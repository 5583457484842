import React, { useEffect, useRef, useState, useMemo } from 'react';
import { useParams } from 'react-router';
import Card from 'react-bootstrap/Card';
import FormButton from '../../components/FormButton';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Project from '../../services/project';
import Director from '../../services/director';
import { PlusLg } from 'react-bootstrap-icons';
import { Floppy, Trash } from 'react-bootstrap-icons';
import FormInput from '../../components/FormInput';
import SearchBar from '../SearchBar';
import Content from '../Content';
import AlertBox from '../AlertBox';
import LoadingPage from '../LoadingPage';
import CellDocumentLinkRenderer from '../CellDocumentLinkRenderer';

import Utils from '../../utils';
import './index.css';

import CommentList from '../CommentList';
import FileList from '../FileList';

import { AgGridReact } from 'ag-grid-react'; // AG Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid

export default function PageProjectEdit() {
    const [id, setId] = useState(useParams().id);
    const [disableDelete, setDisableDelete] = useState(false);
    const [isDirty, setIsDirty] = useState(false);
    const [reload, setReload] = useState(true);
    const [alert, setAlert] = useState(null);
    const [project, setProject] = useState({});
    const [directors, setDirectors] = useState([]);
    const [showDirectorList, setShowDirectorList] = useState(false);
    const [commonAreaName, setCommonAreaName] = useState('');
    const [commonAreaArea, setCommonAreaArea] = useState(0);
    const [uploadResponseAlert, setUploadResponseAlert] = useState([]);

    const [submitting, setSubmitting] = useState(false);

    const [loading, setLoading] = useState(true);
    const [projectLoading, setProjectLoading] = useState(true);

    const [colDefsDirectors, setColDefsDirectors] = useState([
            {
                headerName: 'Directors',
                field: 'name',
                cellRenderer: CellDocumentLinkRenderer,
                cellRendererParams: (params) => { return { onClick: () => onAddDirector(params.data.id), title: params.data.name } }
            }
        ]);
        const defaultColDef = useMemo(() => {
            return {
                flex: 1,
                comparator:  Utils.defaultComparator,
            };
        }, []);

    useEffect(() => {
        setLoading(projectLoading)
    }, [projectLoading]);

    useEffect(() => {
        setSubmitting(false);
        setShowDirectorList(false);
    }, [alert, uploadResponseAlert]);

    const mounted = useRef(true);

    useEffect(() => {
            if (showDirectorList) {
                Director.get('')
                    .then(items => {
                        setDirectors(items);
                    });
            }
        }, [showDirectorList]);

    useEffect(() => { document.title = "Edit Project"; }, []);

    useEffect(() => {
        mounted.current = true;
        if (!reload) {
            return;
        }

        Project.getById(id)
            .then(item => {
                if (mounted.current) {
                    setProject(item);
                    setDisableDelete(item.ProjectUnits && item.ProjectUnits.length > 0);
                    setProjectLoading(false);
                    setReload(false);
                    setSubmitting(false);
                }
            })
            .catch(e => {
                //console.log(e);
                window.location.href = "/notfound";
            });
        return () => mounted.current = false;
    }, [reload]);

    useEffect(() => {
        if (isDirty) {
            setAlert(null);
            window.addEventListener("beforeunload", beforeUnloadHandler);
        }
        return () => {
            window.removeEventListener("beforeunload", beforeUnloadHandler);
        }
    }, [isDirty]);

    const beforeUnloadHandler = (event) => {
        event.preventDefault();
        event.returnValue = true;
    };

    const onFieldChanged = (field, value) => {
        if (project) {
            setIsDirty(true);
            setProject({ ...project, [field]: value });
        }
    }

    const onSave = () => {
        setSubmitting(true);
        Project.addUpdate(project)
            .then(item => {
                if (mounted.current) {
                    if (item.error) {
                        return setAlert({ message: item.error, isError: true });
                    }

                    setId(item.id);
                    setIsDirty(false);
                    setProject(item);
                    setAlert({ message: `Saved`, isError: false });
                }
            });
    }

    const onDelete = () => {
        if (window.confirm("Are you sure you want to delete?") === true) {
            setSubmitting(true);
            setIsDirty(false);
            Project.delete(project).then(item => {
                if (item.error) {
                    setAlert({ message: 'Unable to delete', isError: true });
                    return;
                }
                window.location.href = '/projects'
            });
        } else {
            // Do nothing
        }
    }

    const uploadProjectImage = async (e) => {
        setSubmitting(true);
        Project.setImage(id, e.target.files[0])
            .then(() => {
                setAlert({ message: `Image uploaded`, isError: false });
            })
            .catch(e => {
                setAlert({ message: 'Image upload failed', isError: true });
            });
        e.target.value = '';
    }

    const onRemoveDirectorClick = (directorId) => {
            if(isDirty) {
                return setAlert({ message: 'Please save before removing director', isError: true });
            }
    
            if (window.confirm("Are you sure you want to delete?") === true) {
                setSubmitting(true);
                Project.deleteDirector(project.id, directorId)
                    .then(() => {
                        setAlert({ message: 'Director removed', isError: false });
                        setReload(true);
                    });
            } else {
                // Do nothing
            }
        }
    
        const onAddDirector = (directorId) => {
            if(isDirty) {
                return setAlert({ message: 'Please save before adding director', isError: true });
            }
    
            setSubmitting(true);
            Project.addDirector(id, directorId)
                .then((item) => {
                    if (item.error) {
                        return setAlert({ message: item.error, isError: true });
                    }
    
                    setAlert({ message: 'Director added', isError: false });
                    setReload(true);
                });
        }

    const addCommonArea = () => {
        if (isDirty) {
            return setAlert({ message: 'Please save before adding common area', isError: true });
        }

        if (commonAreaName === '') {
            return setAlert({ message: 'Common Area Name cannot be blank', isError: true });
        }

        if (commonAreaArea <= 0) {
            return setAlert({ message: "Common Area's Area cannot be less than or equal to 0", isError: true });
        }

        setSubmitting(true);
        Project.addCommonArea(id, { name: commonAreaName, area: commonAreaArea })
            .then(() => {
                setAlert({ message: 'Common Area added', isError: false });
                setReload(true);
                setCommonAreaName('');
                setCommonAreaArea(0);
                setReload(true);
            });
        }

    const deleteCommonArea = (commonAreaId) => {
        if (isDirty) {
            return setAlert({ message: 'Please save before deleting common area', isError: true });
        }

        if (window.confirm("Are you sure you want to delete?") === true) {
            setSubmitting(true);
            Project.deleteCommonArea(commonAreaId)
                .then(() => {
                    setAlert({ message: 'Common Area deleted', isError: false });
                    setReload(true);
                });
        } else {
            // Do nothing
        }
    }

    const viewDistribution = () => {
        if (isDirty) {
            return setAlert({ message: 'Please save before viewing distribution', isError: true });
        }

        window.location.href = `/projects/${id}/commonareas`;
    }

    return (
        <LoadingPage loading={loading} submitting={submitting}>
            <SearchBar>
                <Row style={{ display: 'flex', alignItems: 'center' }}>
                    <Col sm style={{ display: 'flex', alignItems: 'center', fontStyle: 'bold' }}>
                        <a href='/projects' style={{ paddingRight: '5px', paddingLeft: '5px' }}>Projects</a> /
                        <span style={{ paddingRight: '5px', paddingLeft: '5px' }}>{project.name}</span>
                    </Col>
                    {id !== '0' && !disableDelete && <Col sm="auto"><Button variant="outline-danger" onClick={() => onDelete()} title='Delete' disabled={submitting}><Trash size={30} /></Button></Col>}
                    <Col sm="auto"><Button variant="outline-success" onClick={() => onSave()} title='Save' disabled={submitting}><Floppy size={30} /></Button></Col>
                </Row>
            </SearchBar>
            <Content>
                <Row>
                    <Col>
                        <Row>
                            <Col lg>
                                <FormInput value={project.name || ''} label='Name' onChange={(e) => onFieldChanged('name', e.target.value)} />
                            </Col>
                            <Col lg>
                                <FloatingLabel label='Project Image' className="mb-3">
                                    <Form.Control type="file" name="file" accept=".png" onChange={(e) => uploadProjectImage(e)} disabled={submitting} />
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <FormInput value={project.details || ''} label='Details' onChange={(e) => onFieldChanged('details', e.target.value)} as='textarea' style={{ height: '100px' }} />

                        <Row style={{ borderBottomStyle: 'dashed', borderBottomColor: '#D0AE6B', borderBottomWidth: '2px', marginBottom: '20px', marginLeft: '2px', marginRight: '2px' }}></Row>
                        <Row>
                            <Col lg>
                                <FormInput value={project.companyName || ''} label='Company Name' onChange={(e) => onFieldChanged('companyName', e.target.value)} />
                            </Col>
                            <Col lg>
                                <FormInput value={project.companyRegistrationNumber || ''} label='Company Registration Number' onChange={(e) => onFieldChanged('companyRegistrationNumber', e.target.value)} />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg>
                                <FormInput value={project.companyRegistrationAddress || ''} label='Company Registration Address' onChange={(e) => onFieldChanged('companyRegistrationAddress', e.target.value)} />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg>
                                <FormInput value={project.companyPANNumber || ''} label='Company PAN Number' onChange={(e) => onFieldChanged('companyPANNumber', e.target.value)} />
                            </Col>
                            <Col lg>
                                <FormInput value={project.companyGSTNumber || ''} label='Company GST Number' onChange={(e) => onFieldChanged('companyGSTNumber', e.target.value)} />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg>
                                <FormInput value={project.reraRegistrationNumber || ''} label='RERA Registration Number' onChange={(e) => onFieldChanged('reraRegistrationNumber', e.target.value)} />
                            </Col>
                        </Row>
                        <Row style={{ borderBottomStyle: 'dashed', borderBottomColor: '#D0AE6B', borderBottomWidth: '2px', marginBottom: '20px', marginLeft: '2px', marginRight: '2px' }}></Row>
                        <Row>
                            <Col lg>
                                <FormInput value={project.landArea || ''} label='Land Area' onChange={(e) => onFieldChanged('landArea', e.target.value)} />
                            </Col>
                            <Col lg>
                                <FormInput value={project.landDetails || ''} label='Land Details' onChange={(e) => onFieldChanged('landDetails', e.target.value)} />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg>
                                <FormInput value={project.city || ''} label='City' onChange={(e) => onFieldChanged('city', e.target.value)} />
                            </Col>
                            <Col lg>
                                <FormInput value={project.taluka || ''} label='Taluka' onChange={(e) => onFieldChanged('taluka', e.target.value)} />
                            </Col>
                            <Col lg>
                                <FormInput value={project.district || ''} label='District' onChange={(e) => onFieldChanged('district', e.target.value)} />
                            </Col>
                            <Col lg>
                                <FormInput value={project.state || ''} label='State' onChange={(e) => onFieldChanged('state', e.target.value)} />
                            </Col>
                        </Row>
                        <Row style={{ borderBottomStyle: 'dashed', borderBottomColor: '#D0AE6B', borderBottomWidth: '2px', marginBottom: '20px', marginLeft: '2px', marginRight: '2px' }}></Row>
                        <Row>
                            <Col lg>
                                <Table bordered hover className='commonarea-table'>
                                    <thead>
                                        <tr>
                                            <th colSpan='3'>
                                                <Row style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Col lg>Common Areas
                                                    </Col>
                                                    <Col lg='auto'>
                                                        <FormButton variant='success' onClick={() => viewDistribution()}>View Distribution</FormButton>
                                                    </Col>
                                                </Row></th>
                                        </tr>
                                        <tr>
                                            <th>Name</th>
                                            <th>Area (sq.ft.)</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><FormInput value={commonAreaName} onChange={(e) => setCommonAreaName(e.target.value)} /></td>
                                            <td><FormInput type='number' value={commonAreaArea} onChange={(e) => setCommonAreaArea(e.target.value)} /></td>
                                            <td className='center'><FormButton variant='success' onClick={() => addCommonArea()}><PlusLg /></FormButton></td>
                                        </tr>
                                        {project.ProjectCommonAreas && project.ProjectCommonAreas.map((s, i) =>
                                            <tr key={i}>
                                                <td>{s.name}</td>
                                                <td className='number'>{s.area}</td>
                                                <td className='center'><Trash className='text-danger' title='Delete' style={{ cursor: 'pointer' }} onClick={() => deleteCommonArea(s.id)} /></td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </Col>
                    <Col sm='3'>
                        <FileList modelId={id} modelType='project' />
                    </Col>
                    <Col sm='auto'>
                        {id !== '0' &&
                            <Row className='mb-2'>
                                <Col>
                                    <Card style={{ textAlign: 'center' }}>
                                        <Card.Header>Directors</Card.Header>
                                        <Card.Body style={{ textAlign: 'right' }}>
                                            {project.Directors && project.Directors.map((c, i) =>
                                                <Row key={i} style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Col lg>
                                                        <Card key={i} className='mb-2' style={{ backgroundColor: '#d0ae6b', cursor: 'pointer', textAlign: 'left' }}>
                                                            <Card.Body>
                                                                <Row>
                                                                    <Col sm onClick={() => window.location.href = `/directors/${c.id}`}>{c.name}</Col>
                                                                    <Col sm='auto'><Trash className='text-danger' onClick={() => onRemoveDirectorClick(c.id)} /></Col>
                                                                </Row>
                                                            </Card.Body>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            )}
                                            <Row style={{ display: 'flex', alignItems: 'center' }}>
                                                <Col lg><FormButton variant='success' onClick={() => setShowDirectorList(true)}><PlusLg size={30} /></FormButton></Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        }
                        <CommentList modelId={id} />
                    </Col>
                </Row>
            </Content>

            {alert &&
                <AlertBox isError={alert.isError} onClose={() => setAlert(null)}>
                    {alert.message}
                </AlertBox>}

            {showDirectorList &&
                <AlertBox isError={false} onClose={() => setShowDirectorList(false)}>
                    <div className="ag-theme-quartz" style={{ height: '20rem', width: '28rem' }}>
                        <AgGridReact
                            defaultColDef={defaultColDef}
                            columnDefs={colDefsDirectors}
                            // domLayout="autoHeight"
                            suppressScrollOnNewData='true'
                            rowData={directors}
                        />
                    </div>
                </AlertBox>}
            {/* <div style={{textAlign: 'left'}}><pre>
                {JSON.stringify(project, null, 2)}
            </pre></div> */}
        </LoadingPage>
    )
}